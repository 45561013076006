@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.ButtonContainer {
  padding-top: $spacing-large;
}

.Error {
  color: $orange;
}

.NoAccount {
  margin-top: $spacing-medium;
  font-size: $xxxs-font-size;
}