@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Spinner {
  border-radius: 50%;
  width: 24px;
  height: 24px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}
