@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  justify-content: center;
  align-items: center;
}

.Header {
  display: flex;
  justify-content: center;
  align-items: center;

  padding: $spacing-large 0;
}

.LoginForm {
  width: 100%;
  height: 100%;

  padding: $spacing-medium;

  @media screen and (min-width: $medium-screen) {
    width: 50%;
    height: 50%;
  }
}

