@import "styles/_variables.scss"; @import "styles/_fonts.scss";
.Container {
  display: flex;
  flex-direction: column;
}

.Input {
  color: $darkGreen;

  all: unset;
  z-index: 2;
  padding: $spacing-small 0 $spacing-medium;
  width: 100%;
}

.InputContainer {
  border-bottom: $sand-border;
}

.PostalCode {
  position: relative;

  &::before {
    content: "LT -";
    position: absolute;
    color: gray;
    left: 0;
    padding: $spacing-small 0;
  }

  padding-left: 40px;
}

.InputError {
  border-bottom: $orange-border;
}

.Label {
  label {
    @include label-font;

    color: gray;
    transition: transform 0.3s, opacity 0.3s;
    z-index: 1;
  }
}

.Error {
  @include label-font;

  padding-top: $spacing-extra-small;
  color: $orange;
}

.Hint {
  @include label-font;

  padding-top: $spacing-extra-small;
  color: $light-gray;
}

.Hidden {
  label {
    opacity: 0;
    transform: translateY(20px);
  }
}

.Disabled {
  opacity: 0.5;
}
